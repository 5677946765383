import React from "react"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const BuildSection = () => (
  <section
    style={{
      background: "#fff",
      padding: "3rem 0",
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-6 results">
          <h2>Explore Our Quickbase Knowledge Base</h2>
          <p className="mt-4">
            Our Quickbase experts regularly create articles based on our
            experience building applications with clients to show you how to get
            more out of the platform.
          </p>
          <p className="font-weight-bolder">You’ll learn how to: </p>
          <ul>
            <li>Build applications</li>
            <li>Refine permissions</li>
            <li>Integrate Quickbase apps</li>
            <li>Follow Quickbase app development best practices</li>
            <li>And more…</li>
          </ul>
          <Link
            className="btn btn-primary btn-arrow mt-4"
            to="/knowledge-base/"
          >
            Explore the Knowledge Base
          </Link>
        </div>
        <div className="col-lg-6 align-self-center pt-3">
          <LazyLoadComponent>
            <StaticImage
              placeholder="blurred"
              className="rounded d-flex w-100"
              src="https://cdn.sanity.io/images/55lcecww/production/f7f0139188e9147c4205c7d5def11e887023b750-1315x630.png"
              alt="Clear Reporting"
              layout="constrained"
              style={{ height: "18rem" }}
              loading="lazy"
              fetchpriority="low"
            />
          </LazyLoadComponent>
        </div>
      </div>
    </div>
  </section>
)

export default BuildSection
